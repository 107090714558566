import { useState, KeyboardEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Button } from '../../fragments/button';
import { InfoPopup } from '../../fragments/info-popup';
import { TextInput } from '../../fragments/text-input';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { LoginForm, Root, Container } from './login.styles';
import useAnalytics from '../../hooks/useAnalytics';
import { useUserStore } from '../../stores/useUserStore';
import { AuthStatus } from '../../types/bzzt';

type LoginProps = {} & RouteComponentProps<{}, {}, { from: string }>;

export function Login({ location }: LoginProps) {
    const userStore = useUserStore();
    const analytics = useAnalytics();
    const { from } = location.state || { from: { pathname: '/' } };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLoginClicked = async () => {
        await userStore.login(email, password);
        await analytics.identify({
            email: email,
            traits: {},
        });
    };

    const handleKeyDown = async (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            await handleLoginClicked();
        }
    };

    if (userStore.authenticated()) {
        return <Redirect to={from} />;
    }

    const dissmissErrorPopup = () => {
        userStore.unauthenticate();
    };

    return (
        <Screen
            compact
            topContent={<Title marginTop={'5rem'}>Välkommen till Bzzt!</Title>}
            content={
                <Root>
                    <LoginForm>
                        <TextInput
                            placeholder="mejladress"
                            type="email"
                            onChange={(e: any) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <TextInput
                            placeholder="lösenord"
                            type="password"
                            onChange={(e: any) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        {/* TODO not ready <Checkbox label="Håll mig inloggad" labelAlign="right" /> */}
                    </LoginForm>
                    {userStore.status == AuthStatus.ERROR && (
                        <InfoPopup
                            header="Oops, inloggningen misslyckades!"
                            positiveButtonText="Ok"
                            onSubmit={dissmissErrorPopup}
                            centerButtons
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <Container>
                    <Button
                        paddingWidth={'3.5rem'}
                        paddingHeight={'0.2rem'}
                        fillParent
                        onClick={handleLoginClicked}
                    >
                        Logga in
                    </Button>
                    {/* TODO not ready <Text>Glömt lösenordet?</Text> */}
                </Container>
            }
        />
    );
}
